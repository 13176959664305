<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :close-on-content-click="false"
    :disabled="disabled"
    :left="displayMenuOnLeft"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-if="!hideDisplay"
        :class="`date-field${!dateValue ? ' empty' : ''}`"
        readonly
        :value="displayDate"
        :label="label"
        prepend-icon="stem-calendar"
        color="fields"
        v-bind="attrs"
        v-on="on"
        :clearable="isClearable"
        :disabled="disabled"
        @click:clear="dateChange()"
      />
      <v-btn v-if="hideDisplay" icon text v-bind="attrs" v-on="on">
        <IconBox color="iconButton"> stem-calendar </IconBox>
      </v-btn>
    </template>
    <v-date-picker
      :value="dateValue"
      @input="dateChange"
      no-title
      scrollable
      color="fields"
      locale="pl-PL"
      @change="menu = false"
      first-day-of-week="1"
      :max="maxAllowedDate"
      :min="minAllowedDate"
    />
  </v-menu>
</template>

<script>
import moment from "@/plugins/moment";

export default {
  name: "DateField",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      require: true,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: Object,
    },
    minDate: {
      type: Object,
    },
    hideDisplay: {
      type: Boolean,
      default: false,
    },
    displayMenuOnLeft: {
      type: Boolean,
      default: false,
    },
    endOfDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateValue: function () {
      if (this.value == null) {
        return undefined;
      } else if (moment.isMoment(this.value)) {
        return moment(this.value).format("date");
      } else if (typeof this.value === "string") {
        return moment(this.value).format("date");
      }
      return undefined;
    },
    displayDate() {
      return this.dateValue
        ? moment(this.dateValue, "YYYY-MM-DD").format("displayDate")
        : "";
    },
    maxAllowedDate() {
      return this.maxDate ? moment(this.maxDate).format("date") : undefined;
    },
    minAllowedDate() {
      return this.minDate ? moment(this.minDate).format("date") : undefined;
    },
  },
  methods: {
    dateChange(newDate) {
      const date = newDate ? moment(newDate, "YYYY-MM-DD") : null;

      if (this.endOfDay && date) {
        date.set("hour", 23).set("minute", 59).set("second", 59);
      }

      this.$emit("input", date ? date.format() : null);
    },
  },
  watch: {
    maxDate(value) {
      if (!value) return;

      const maxDate = moment(value);
      const selectedDate = this.value ? moment(this.value) : null;

      if (moment(selectedDate).isAfter(maxDate)) {
        const date = moment(value).format();
        this.$emit("input", date);
      }
    },
    minDate(value) {
      if (!value) return;

      const minDate = moment(value);
      const selectedDate = this.value ? moment(this.value) : null;

      if (moment(selectedDate).isBefore(minDate)) {
        const date = moment(value).format();
        this.$emit("input", date);
      }
    },
  },
};
</script>
